import client from "@sendgrid/client";
import axios from "axios";
import {
  CONTACT_FROM_CLEAR_FROM,
  CONTACT_FROM_SUBMIT,
  CONTACT_FROM_SUBMIT_FAILURE,
  CONTACT_FROM_SUBMIT_SUCCESS,
  CONTACT_FROM_UPDATE_FIELD,
  GET_FAQS,
  GET_FAQS_FAILURE,
  GET_FAQS_SUCCESS,
  MAILING_LIST_ADD_EMAIL,
  MAILING_LIST_ADD_EMAIL_FAILURE,
  MAILING_LIST_ADD_EMAIL_SUCCESS,
  MAILING_LIST_CLEAR_FROM,
  MAILING_LIST_UPDATE_EMAIL,
} from "../../constants/types";
import { parseError } from "./ActionHelpers";

export const updateMailingListEmail = (email) => {
  return {
    type: MAILING_LIST_UPDATE_EMAIL,
    payload: email,
  };
};

export const addEmailToMailingList = (email) => (dispatch) => {
  dispatch({
    type: MAILING_LIST_ADD_EMAIL,
  });
  if (
    !email ||
    !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      email.toString().toLowerCase(),
    )
  ) {
    dispatch({
      type: MAILING_LIST_ADD_EMAIL_FAILURE,
      payload: "Please enter a valid email",
    });
    return;
  }

  const data = {
    email: email,
  };

  axios
    .post("/communications/add_email_to_list/", data, {
      baseURL: process.env.REACT_APP_CORE_URL
        ? "https://" + process.env.REACT_APP_CORE_URL
        : "http://localhost:8000",
    })
    .then((response) => {
      dispatch({
        type: MAILING_LIST_ADD_EMAIL_SUCCESS,
      });
    })
    .catch((error) => {
      console.error(error);
      const errMsg = parseError(error);
      dispatch({
        type: MAILING_LIST_ADD_EMAIL_FAILURE,
        payload: errMsg,
      });
    });
};

export const getFreshdeskFAQs = () => (dispatch) => {
  dispatch({
    type: GET_FAQS,
  });

  axios
    .get("/communications/get_faqs/", {
      baseURL: process.env.REACT_APP_CORE_URL
        ? "https://" + process.env.REACT_APP_CORE_URL
        : "http://localhost:8000",
    })
    .then((res) => {
      dispatch({
        type: GET_FAQS_SUCCESS,
        payload: JSON.parse(res.data),
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: GET_FAQS_FAILURE,
        payload: parseError(err),
      });
    });
};
