import React, { useEffect, useState } from "react";
import contact_image from "../../assets/contact-image.png";
import "../../scss/contact.scss";
import { Spinner } from "react-bootstrap";
import HubspotForm from "react-hubspot-form";
import { ReactComponent as SuccessIcon } from "../../assets/contact-from-success.svg";

const Contact = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);

  return (
    <div className="landing contact-page d-flex flex-row justify-content-end">
      {formSubmitted && (
        <div
          className={
            "success col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center"
          }
        >
          <SuccessIcon />
          <h4>We've received your message</h4>
          <p className={"info"}>
            Thanks for reaching out! Our team will reach out to you soon.
          </p>
        </div>
      )}
      <div
        className={`content justify-content-center align-items-center w-100  ${formSubmitted ? "d-none" : "d-block"}`}
      >
        <HubspotForm
          portalId="49068091"
          formId="d96d700c-8131-445e-893b-4acc4a2b133e"
          region={"na1"}
          loading={<Spinner animation={"border"} />}
          onSubmit={() => {
            setFormSubmitted(true);
          }}
        />
      </div>
      <div className="image d-none d-lg-flex col-12 col-lg-6 p-0">
        <img
          src={contact_image}
          alt={"Contact us: lady with tablet in warehouse"}
        />
      </div>
    </div>
  );
};

export default Contact;
